import { get, post, put, patch, del } from '../../networkClient';
import { Path } from '../path';

export function dupNewAccount(payload) {
  return post(Path.SELF_SERVE_PORTAL_NEW, payload);
}

export function createNewChannelAccount(payload) {
  return post(Path.CREATE_NEW_CHANNEL_ACCOUNT, payload);
}

export function deleteChannelAccountApi({ email }) {
  return del(`${Path.DELETE_NEW_CHANNEL_ACCOUNT}?email=${email}`);
}
export function updateChannelAccountEmailStatusApi(payload) {
  return post(Path.UPDATE_CHANNEL_EMAIL_STATUS, payload);
}
export function updatePortal(payload) {
  return put(Path.SELF_SERVE_UPDATE, payload);
}

export function getTenantDetailsApi(params) {
  return get(`${Path.GET_TENANT_DETAILS}?tenantId=${params}`);
}
export function getYardiCommunityAgentsApi(payload) {
  return post(Path.GET_YARDI_COMMUNITY_AGENTS, payload);
}
export function updateLeasingAgentsApi(payload) {
  return patch(Path.UPDATE_LEASING_AGENTS, payload);
}

export function verifyCommunityApi(payload) {
  return post(Path.VERIFY_COMMUNITY, payload);
}
export function ingestCommuntiyApi(payload) {
  return post(Path.INGEST_COMMUNITY, payload, null, null, true);
}
export function downloadCsvApi(params) {
  return get(`${Path.DOWNLOAD_CHEATSHEET}?tenantId=${params}`);
}
export function downloadCheatSheetByApartmentIdsApis(payload) {
  return post(`${Path.DOWNLOAD_CHEATSHEET_BY_APARTMENT_IDS}`, payload);
}
export function uploadCsv(payload) {
  return post(
    Path.UPLOAD_CHEATSHEET,
    payload,
    {},
    { 'Content-Type': 'multipart/form-data' }
  );
}
export function validatePmsApi(payload) {
  return post(Path.VALIDATE_PMS, payload);
}

export function ingestedCommunitiesApiGet(payload) {
  return get(`${Path.INGESTED_COMMUNITIES}?tenantId=${payload}`);
}

export function getUserDataApi() {
  return get(`${Path.GET_ALL_USERS_DATA}`);
}

export function createInternalSettingsApi(payload) {
  return post(Path.INTERNAL_SETTINGS, payload);
}
export function updateRestrictCommunicationApi(payload) {
  return patch(Path.RESTRICT_COMMUNICATION, payload);
}

export function verifyRentCafeApi(payload) {
  return post(Path.VERIFY_RENT_CAFE, payload);
}

export function getInternalSettingsApi(params) {
  return get(`${Path.INTERNAL_SETTINGS}${params ? `?tenantId=${params}` : ''}`);
}

export function updateWorkingHoursApi(payload) {
  return patch(Path.UPDATE_WORKING_HOURS_SELF_SERVE, payload);
}

export function getChannelAccountDetailsApi(params) {
  return get(
    `${Path.GET_CHANNEL_ACCOUNT}${params ? `?tenantId=${params}` : ''}`
  );
}
export function onboardCommunitiesApi(payload) {
  return put(Path.ONBOARD_COMMUNITY, payload);
}
export function getIntegration(params) {
  return get(Path.INTEGRATION_SELF_SERVE, params);
}
export function postIntegrationFlagsApi(payload) {
  return patch(Path.GET_INTEGRATION_FLAG, payload);
}
export function configIntegrationApi(payload) {
  return put(Path.CONFIGURE_INTEGRATION, payload);
}
export function deletePhoneNumber(payload) {
  return put(Path.DELETE_PHONE_NUMBER, payload);
}
