const List = [
  'Affordable Housing',
  'Amenities',
  'Applied Already',
  'Booked by Leasing Office',
  'Commute',
  'Corporate Lease',
  'Converted to Lease',
  'Covid-19',
  'Current Resident',
  'Did not explain',
  'Did not Like the Tour',
  'Disqualified',
  'Disqualified - Age',
  'Disqualified - credit',
  'Disqualified - Criminal',
  'Disqualified - Income',
  'Disqualified - Pets',
  'Disqualified - Rental History',
  'Duplicate',
  'Floorplan layout',
  'Floorplan size',
  'Furnishing Options',
  'Gym',
  'Invalid Contact Information',
  'Lease Length',
  'Leased at Sister Community',
  'Leased Elsewhere',
  'Looking to pre-lease',
  'No Longer Interested',
  'No Tours Available',
  'Noise',
  'Not A Lead',
  'Not Ready To Lease',
  'Not Ready To Tour',
  'Not Interested in Coliving',
  'Other',
  'Preferred Unit Not Available',
  'Property No Longer Available',
  'School',
  'Self Booked Tour',
  'Too Expensive',
  'Test Lead',
  'Undecided',
  'Unresponsive',
  'Waiting For Self-Guided Tour',
  'Walk-in',
  'Walkability',
  'Waitlisted',
  'Stopped responding',
  'Opted out of communication',
  'Never responded',
  'Created by on-site team',
  'Answered by leasing office',
  'Landline call-to-text lead'
];

export default List;
