import { DateRange } from '@material-ui/icons';
import { FormControl, MenuItem, Select } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTimeSlots } from '../../../Redux/Actions/InboxV1';
import {
  setGlobalError,
  setSuccessInfo
} from '../../../Redux/Reducer/GlobalError';
import { setMessagesEmpty } from '../../../Redux/Reducer/InboxV1';
import { DAYS_LIST, MONTHS_LIST } from '../../../utils';
import Loader from '../../../Component/Loader';
import DateInput from '../../../Component/DateInput';
import { useTheme } from 'styled-components';
import { getListOfAvailableTourTypesForUI } from '../../../Component/TourSchedulingWidget/utils';
import { makeStyles } from '@material-ui/core';
import {
  fetchAvailableTourTypesByApartment,
  fetchTimeSlotsByApartment
} from './networkCallUtils';

const useStyles = makeStyles(theme => ({
  dialogContainer2: props => ({
    display: 'flex',
    gap: '10px',
    marginTop: '20px'
  }),

  inputRoot: props => ({
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: theme.spacing(0.5),
      border: `0.5px solid ${props.border.primary}`,
      color: props.text.primary
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3f51b5'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
      color: '#3f51b5'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: props?.text?.placeholder
    }
  }),

  labelRoot: props => ({
    '&.MuiTypography-root': {
      paddingBottom: theme.spacing(1),
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: theme.spacing(2),
      color: props.text.primary
    }
  }),
  calendarContainer: props => ({
    padding: '10px',
    background: props.background.gradient,
    display: 'flex',
    borderRadius: '6px 6px 0 0'
  }),
  customDatePicker: props => ({
    padding: '8px',
    background: 'transparent',
    color: props.text.primary,
    borderRadius: theme.spacing(0.5),
    border: `0.5px solid ${props.border.primary}`,
    display: 'flex',
    width: '150px'
  }),
  bodyContainer: {
    margin: '20px 0',
    borderRadius: theme.spacing(0.5)
  },
  datesWrapper: props => ({
    border: `0.5px solid ${props.border.primary}`,
    borderRadius: '4px',
    padding: '10px',
    color: props.text.label,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    marginRight: theme.spacing(1),
    minWidth: '52px'
  }),
  datesWrapperContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowY: 'scroll',
    marginLeft: '10px'
  },
  datesContainer: {
    display: 'flex',
    cursor: 'pointer'
  },
  dayWrapper: props => ({
    position: 'absolute',
    padding: '1px 6px',
    marginTop: '-16px',
    marginLeft: '8px',
    background: props.background.primary,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '10px',
    color: props.text.placeholder,
    borderRadius: '1px'
  }),
  timeWrapper: props => ({
    border: '0.5px solid #393939',
    borderRadius: '5px',
    padding: '12px 14px',
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '15px',
    color: props.text.heading,
    minWidth: '76px',
    textAlign: 'center',
    margin: 0,
    maxHeight: '16px',
    cursor: 'pointer'
  }),
  fadedTimeWrapper: {
    border: '0.5px solid grey',
    borderRadius: '5px',
    padding: '12px 14px',
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '15px',
    color: 'grey',
    minWidth: '76px',
    textAlign: 'center',
    margin: 0,
    cursor: 'pointer',
    opacity: '0.5'
  },
  timeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    height: '160px',
    overflowY: 'scroll'
  },
  timeSlotsText: props => ({
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '15px',
    color: props.text.heading,
    padding: '10px 0',
    margin: 0
  }),
  toggler: props => ({
    width: '202px',
    height: '31px',
    display: 'flex',
    justifyContent: 'space-between',
    background: 'rgba(82, 103, 255, 0.1)',
    border: `0.543px solid ${props.border.selection}`,
    borderRadius: '4px',
    margin: '20px 0 0 0'
  }),

  slotsContainer: props => ({
    border: `solid 1px ${props?.border?.primary}`,
    borderRadius: '6px',
    flexGrow: 1
  }),
  calendarAndSlotsContainer: {
    paddingLeft: '28px',
    paddingBottom: '10px'
  }
}));

const TourDetails = ({ apartmentData, apartmentId }) => {
  const [availableTourTypes, setAvailableTourTypes] = useState([]);
  const [selectedDate, setSelectedData] = useState(new Date());

  useEffect(() => {
    let selectedDateTemp =
      dates?.length > 0
        ? new Date(moment(dates[0])?.format('YYYY MM DD'))
        : new Date();

    setSelectedData(selectedDateTemp);
  }, []);

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <button className={classes.customDatePicker} onClick={onClick} ref={ref}>
      <DateRange />
      <Typography sx={{ marginLeft: '10px' }}>
        {value ? value : 'Select Date'}
      </Typography>
    </button>
  ));

  const [tourList, setTourList] = useState([]);
  const [tourTypeValue, setTourTypeValue] = useState('');

  useEffect(() => {
    fetchAvailableTourTypesByApartment(apartmentId)
      .then(res => {
        setAvailableTourTypes(res?.data?.data?.tourTypes);

        dispatch(setSuccessInfo(res?.data?.message));
      })
      .catch(err => {
        const errMessage = err?.response?.data?.message;
        dispatch(setGlobalError(res?.data?.message));
      });
  }, [apartmentId]);

  useEffect(() => {
    const availableTourTypesByApartment =
      getListOfAvailableTourTypesForUI(availableTourTypes);

    setTourList(availableTourTypesByApartment);
  }, [availableTourTypes]);

  const dispatch = useDispatch();

  const [slotsLoading, setSlotsLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const [dates, setDates] = useState([]);
  const [slotsMessage, setSlotsMessage] = useState('');
  const [slotsErrorMessage, setSlotsErrorMessage] = useState('');

  useEffect(() => {
    if (tourTypeValue !== '') {
      setSlotsLoading(true);
      fetchTimeSlotsByApartment(
        apartmentId,
        tourTypeValue === 'selfguided' ? 'inperson' : tourTypeValue
      )
        .then(res => {
          setSlots(res.data.data?.slots || []);
          setDates(res.data.data?.dates || []);
          setSlotsMessage(res.data.message || '');
        })
        .catch(err => {
          console.error('Error fetching slots:', err);
          setSlotsErrorMessage('Failed to load time slots');
        })
        .finally(() => {
          setSlotsLoading(false);
        });
    }
  }, [tourTypeValue]);

  const selectCustomDate = item => {
    setSelectedData(moment(item).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (slotsMessage) {
      dispatch(setSuccessInfo(slotsMessage));
      dispatch(setMessagesEmpty());
    }
  }, [slotsMessage]);

  useEffect(() => {
    if (slotsErrorMessage) {
      dispatch(setGlobalError(slotsErrorMessage));
      dispatch(setMessagesEmpty());
    }
  }, [slotsErrorMessage]);

  useEffect(() => {
    if (!slotsLoading && dates?.length > 0) {
      setSelectedData(dates[0]);
    }
  }, [slotsLoading, dates]);

  const memoisedCallGenerateDates = useCallback(() => {
    const listLength = 60; // days
    let result = [];

    for (let i = 0; i < listLength; i++) {
      let itemDate = new Date(); // starting today
      itemDate.setDate(itemDate.getDate() + i);
      result.push(itemDate);
    }

    return result.map((item, i) => {
      const formattedItemDate = moment(item).format('YYYY-MM-DD');
      const isDateAvailable = dates?.includes(formattedItemDate);
      return (
        <div
          onClick={() => selectCustomDate(item)}
          id="date-select"
          title="date-select"
          key={i}
          style={{
            backgroundColor:
              formattedItemDate == selectedDate
                ? theme.background?.selection
                : null,
            color:
              formattedItemDate == selectedDate ? theme.text?.secondary : null
          }}
          className={
            isDateAvailable
              ? `${classes.datesWrapper} date-box-custom`
              : `${classes.datesWrapper}  date-custom-disable date-box-custom`
          }
        >
          <span className={classes.dayWrapper}>
            {DAYS_LIST[item.getDay()].substring(0, 3).toLowerCase()}
          </span>
          <span>
            {item.getDate()}
            {MONTHS_LIST[item.getMonth()].substring(0, 3).toUpperCase()}
          </span>
        </div>
      );
    });
  }, [dates, selectCustomDate, selectedDate]);
  const [timeSelected, setTimeSelected] = useState(null);
  const [timeSlotsActive, settimeSlotsActive] = useState([]);
  const handleTourType = e => {
    setTourTypeValue(e.target.value);
  };

  const handleTimeCLick = val => {
    setTimeSelected(val);
  };

  useEffect(() => {
    let selectedDay = selectedDate
      ? moment(selectedDate).format('YYYY-MM-DD')
      : moment(dates?.[0]).format('YYYY-MM-DD');
    if (slots?.length > 0) {
      let newslots = slots?.filter(val => val.includes(selectedDay));
      if (newslots?.length > 0) {
        let finalslots = newslots?.map(e => e.slice(0, 19));
        settimeSlotsActive(finalslots);
      }
    }
  }, [selectedDate, slots]);

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div>
      <>
        <DialogContent
          sx={{
            background: theme.background.secondary
          }}
        >
          <div className={classes?.dialogContainer2}>
            <div style={{ width: '276px' }}>
              <Typography className={classes?.labelRoot}>Tour type</Typography>
              <FormControl className={classes?.inputRoot} fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tourTypeValue}
                  required={!tourTypeValue}
                  onChange={handleTourType}
                  placeholder="Select tour type"
                  name="on-platform-tour-type"
                >
                  {tourList &&
                    tourList.length > 0 &&
                    tourList?.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.bodyContainer}>
            <Typography className={classes.labelRoot}>Select slot</Typography>
            <div className={classes.slotsContainer}>
              {!slotsLoading ? (
                <>
                  <div className={classes.calendarContainer}>
                    <DateInput
                      id="select-tour-date"
                      name="select-tour-date"
                      selected={new Date(moment(selectedDate))}
                      placeholderText="Select Date"
                      onChange={date => setSelectedData(date)}
                      minDate={new Date()}
                      includeDates={dates?.map(res => {
                        let finale = moment(res)?.format('YYYY MM DD');
                        return new Date(finale);
                      })}
                      customInput={<CustomDateInput />}
                    />
                    <div className={classes.datesWrapperContainer}>
                      <div className={classes.datesContainer}>
                        {memoisedCallGenerateDates()}
                      </div>
                    </div>
                  </div>
                  <div className={classes.calendarAndSlotsContainer}>
                    <p className={classes.timeSlotsText}>
                      *Available time slots
                    </p>
                    <div className={classes.timeContainer}>
                      {tourTypeValue === '' ? (
                        <span
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '16px',
                            color: theme.text.primary
                          }}
                        >
                          Select tour type to view available slots
                        </span>
                      ) : timeSlotsActive.length === 0 ? (
                        <span
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '16px',
                            color: theme.text.primary
                          }}
                        >
                          No slots available
                        </span>
                      ) : (
                        timeSlotsActive?.map((time, i) => {
                          return (
                            <p
                              id="time-select"
                              key={i}
                              style={{
                                background:
                                  timeSelected === time
                                    ? theme.background.selection
                                    : '',
                                color:
                                  timeSelected === time
                                    ? theme.text.secondary
                                    : ''
                              }}
                              onClick={() => handleTimeCLick(time)}
                              className={classes.timeWrapper}
                            >
                              {moment(time).format('LT')}
                            </p>
                          );
                        })
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    height: '235px',
                    width: '235px',
                    margin: '15px auto'
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </>
    </div>
  );
};

export default TourDetails;
