import { get } from '../../../Redux/networkClient';
import { getCookie } from '../../../utils';

const authorization = getCookie('rb_access_token');
export const fetchAvailableTourTypesByApartment = id => {
  return get(
    `/tours/type/${id}`,
    {},
    {
      'Content-Type': 'application/json',
      Authorization: authorization
    },
    true,
    true
  );
};

export const fetchTimeSlotsByApartment = (id, tourType) => {
  return get(
    `/tours/slots?communityId=${id}&tourType=${tourType}`,
    {},
    {
      'Content-Type': 'application/json',
      Authorization: authorization
    },
    true,
    true
  );
};
