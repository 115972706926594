import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import TourDetails from './TourDetails';

const TourDetailsModal = ({ apartmentData, apartmentId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Button type="primary" onClick={showModal}>
        Load Tour Details
      </Button>
      <Modal
        title="Tour Details"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <TourDetails apartmentData={apartmentData} apartmentId={apartmentId} />
      </Modal>
    </div>
  );
};

export default TourDetailsModal;
