import React from 'react';
import { Button, message, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { useChatbotSettingsContext } from '../../../utils/ContextProviders/ChatbotSettingsContextProvider';
const { Text } = Typography;

const ChatbotScript = () => {
  const { view } = useChatbotSettingsContext();
  const apartmentId = view.communityId;

  const script = `
  <script src="https://apis-v2.residentboost.com/chatbot/script?apartmentId=${apartmentId}"></script>`;

  const handleCopy = () => {
    navigator.clipboard.writeText(script).then(() => {});
    message.success('Copied!');
  };

  return (
    <div
      style={{
        padding: '16px',
        border: '1px solid #e0e0e0',
        borderRadius: '8px'
      }}
    >
      <Text
        strong
        style={{ display: 'block', marginBottom: '16px', fontSize: '16px' }}
      >
        Chatbot Script
      </Text>
      <Text style={{ marginBottom: '16px', display: 'block' }}>
        Copy the script below, paste it into your website, and watch your
        chatbot go live in no time.
      </Text>
      <div style={{ marginBottom: '16px' }}>
        <CodeMirror
          value={script.trim()}
          options={{
            mode: 'javascript', // Highlight JavaScript syntax
            theme: 'material', // Use the Material theme
            lineNumbers: true, // Show line numbers
            readOnly: true // Make the editor read-only
          }}
        />
      </div>
      <Button type="primary" icon={<CopyOutlined />} onClick={handleCopy}>
        Copy Script
      </Button>
    </div>
  );
};

export default ChatbotScript;
