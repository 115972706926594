/**
 * HTTP Service Wrapper
 */
import axios from 'axios';
import { getCookie } from '../utils';
import {
  Development,
  Production,
  dittoApi,
  kelseyBasePath
} from '../serverLinks';
import store from './store';
import { setGlobalError } from './Reducer/GlobalError';

axios.defaults.withCredentials = false;
axios.defaults.crossdomain = true;

function getURL(params) {
  if (params) {
    return kelseyBasePath;
  }
  if (process.env.NODE_ENV === 'development') {
    return Development;
  } else {
    return Production;
  }
}

function handleError(error) {
  if (error.response.status === 403) {
    alert(error.response.data && error.response.data.msg);
  }
}
const handleErrorGlobal = error => {
  let errorMessage;
  const errorData = error?.response?.data;
  if (errorData?.detailedMessage) {
    errorMessage = errorData?.detailedMessage;
  } else if (errorData?.message) {
    errorMessage = errorData?.message;
  } else if (errorData?.errorInfo) {
    errorMessage = errorData?.errorInfo;
  } else {
    errorMessage = 'Something Went Wrong';
  }

  store.dispatch(setGlobalError(errorMessage));
};
export async function get(
  url,
  params,
  headers,
  isBaseUrlRequired = true,
  useNewBaseUrl = false
) {
  try {
    const BASE_URL = isBaseUrlRequired
      ? (useNewBaseUrl && process.env.REACT_APP_SERVER_URL_V2) || getURL()
      : '';

    const response = await axios.get(BASE_URL + url, {
      headers: headers || {
        'Content-Type': 'application/json',
        Authorization: `${getCookie('rb_access_token')}`
      },
      params: params
    });
    // store.dispatch(setGlobalError("Something Went Right"));
    return response;
  } catch (error) {
    return error;
  }
}

//TODO : need to move these to rb-backend
export async function patchKelsey(url, body, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .patch(getURL('kelsey') + url, body, {
        headers: headers
          ? {
              ...headers,
              Authorization: `${getCookie('rb_access_token')}`
            }
          : {
              'Content-Type': 'application/json',
              Authorization: `${getCookie('rb_access_token')}`
            },
        params: params
      })
      .then(responce => {
        resolve(responce);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}

export async function putKelsey(url, body, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .put(getURL('kelsey') + url, body, {
        headers: headers
          ? {
              ...headers,
              Authorization: `${getCookie('rb_access_token')}`
            }
          : {
              'Content-Type': 'application/json',
              Authorization: `${getCookie('rb_access_token')}`
            },
        params: params
      })
      .then(responce => {
        resolve(responce);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}
export async function deleteKelsey(url, body, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .delete(getURL('kelsey') + url, {
        headers: headers
          ? {
              ...headers,
              api_key: 'test123',
              'Content-Type': 'application/json',
              accept: 'application/json'
            }
          : {
              api_key: 'test123',
              'Content-Type': 'application/json',
              accept: 'application/json'
            },
        data: body,
        params: params
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}

export async function getKelsey(
  url,
  params,
  headers,
  isBaseUrlRequired = true
) {
  try {
    const BASE_URL = isBaseUrlRequired ? getURL('kelsey') : '';

    const response = await axios.get(BASE_URL + url, {
      headers: headers || {
        'Content-Type': 'application/json',
        Authorization: `${getCookie('rb_access_token')}`
      },
      params: params
    });
    // store.dispatch(setGlobalError("Something Went Right"));
    return response;
  } catch (error) {
    return error;
  }
}
export async function uploadFile(url, body, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .post(getURL() + url, body, {
        headers: headers
          ? {
              ...headers,
              Authorization: `${getCookie('rb_access_token')}`
            }
          : {
              'Content-Type': 'multipart/form-data',
              Authorization: `${getCookie('rb_access_token')}`
            },
        params: params
      })
      .then(responce => {
        resolve(responce);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}
export async function postKelsey(url, body, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .post(getURL('kelsey') + url, body, {
        headers: headers
          ? {
              ...headers,
              Authorization: `${getCookie('rb_access_token')}`
            }
          : {
              'Content-Type': 'application/json',
              Authorization: `${getCookie('rb_access_token')}`
            },
        params: params
      })
      .then(responce => {
        resolve(responce);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}
export async function post(url, body, params, headers, useNewBaseUrl = false) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        ((useNewBaseUrl && process.env.REACT_APP_SERVER_URL_V2) || getURL()) +
          url,
        body,
        {
          headers: headers
            ? {
                ...headers,
                Authorization: `${getCookie('rb_access_token')}`
              }
            : {
                'Content-Type': 'application/json',
                Authorization: `${getCookie('rb_access_token')}`
              },
          params: params
        }
      )
      .then(responce => {
        resolve(responce);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}

export async function dittoPost(url, body, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .post(dittoApi + url, body, {
        headers: headers || {
          'Content-Type': 'application/json',
          Authorization: `${getCookie('rb_access_token')}`
        },
        params: params
      })
      .then(responce => {
        resolve(responce);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}

export async function put(
  url,
  body,
  params,
  headers,
  isBaseUrlRequired = true
) {
  const BASE_URL = isBaseUrlRequired ? getURL() : '';
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL + url, body, {
        headers: headers || {
          'Content-Type': 'application/json',
          Authorization: `${getCookie('rb_access_token')}`
        },
        params: params
      })
      .then(responce => {
        resolve(responce);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}

export async function patch(
  url,
  body,
  params,
  headers,
  isBaseUrlRequired = true
) {
  const BASE_URL = isBaseUrlRequired ? getURL() : '';
  return new Promise((resolve, reject) => {
    axios
      .patch(BASE_URL + url, body, {
        headers: headers || {
          'Content-Type': 'application/json',
          Authorization: `${getCookie('rb_access_token')}`
        },
        params: params
      })
      .then(responce => {
        resolve(responce);
      })
      .catch(error => {
        handleErrorGlobal(error);
        reject(error);
      });
  });
}

export async function del(
  url,
  body,
  params,
  headers,
  isBaseUrlRequired = true
) {
  const BASE_URL = isBaseUrlRequired ? getURL() : '';
  try {
    const response = await axios.delete(BASE_URL + url, {
      headers: headers || {
        'Content-Type': 'application/json',
        Authorization: `${getCookie('rb_access_token')}`
      },
      data: body,
      params: params
    });
    return response;
  } catch (error) {
    handleErrorGlobal(error);
    return error;
  }
}
